import React from "react";
import { createRoot } from 'react-dom/client';
import './styles.css'
import Circle from './Circle'
import Imprint from './layout/Imprint'
import Overlay from './layout/Overlay'

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const lerp = (a, b, n) => (1 - n) * a + n * b;

class Cursor {
  constructor() {
    // config
    this.target = { x: 0.5, y: 0.5 }; // mouse position
    this.cursor = { x: 0.5, y: 0.5 }; // cursor position
    this.speed = 0.18;
    this.init();
  }
  bindAll() {
    ["onMouseMove", "render"].forEach(fn => this[fn] = this[fn].bind(this));
  }
  onMouseMove(e) {
    //get normalized mouse coordinates [0, 1]
    this.target.x = e.clientX / window.innerWidth;
    this.target.y = e.clientY / window.innerHeight;
    // trigger loop if no loop is active
    if (!this.raf) this.raf = requestAnimationFrame(this.render);
  }
  render() {
    //calculate lerped values
    this.cursor.x = lerp(this.cursor.x, this.target.x, this.speed);
    this.cursor.y = lerp(this.cursor.y, this.target.y, this.speed);
    document.documentElement.style.setProperty("--cursor-x", this.cursor.x);
    document.documentElement.style.setProperty("--cursor-y", this.cursor.y);
    //cancel loop if mouse stops moving
    const delta = Math.sqrt(
    Math.pow(this.target.x - this.cursor.x, 2) +
    Math.pow(this.target.y - this.cursor.y, 2));

    if (delta < 0.001) {
      cancelAnimationFrame(this.raf);
      this.raf = null;
      return;
    }
    //or continue looping if mouse is moving
    this.raf = requestAnimationFrame(this.render)

    
  }
  init() {
    this.bindAll();
    window.addEventListener("mousemove", this.onMouseMove);
    this.raf = requestAnimationFrame(this.render);
    if(isTouchDevice()){
      document.body.classList.add('touchDevice')
    }
  }}

  function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }


new Cursor();

function App() {

  const handleMouseEnter = () => document.body.classList.add('link-hovered');
  const handleMouseLeave = () => document.body.classList.remove('link-hovered');

  return (
    <>
      <Circle />
      <Overlay onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  />
      <Imprint />
    </>
  )
}

root.render(<App />);