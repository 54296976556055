import React from 'react';
import { OrbitControls, CameraShake } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { useControls, Leva } from 'leva';
import { Particles } from './Particles';

export default function Circle() {

  const pixelDensity = window.devicePixelRatio;

  const isDebugMode = window.location.hash.includes('debug');

  const props = useControls({
    focus: { value: pixelDensity > 1 ? 1.12 : 1.42, min: 0, max: 7, step: 0.01 },
    speed: { value: pixelDensity > 1 ? 5 : 4.4, min: 0.1, max: 100, step: 0.1 },
    aperture: { value: pixelDensity > 1 ? 4.0 : 4.5, min: 1, max: 5.6, step: 0.1 },
    fov: { value: pixelDensity > 1 ? 8 : 16, min: 0, max: 200 },
    curl: { value: pixelDensity > 1 ? 0.23 : 0.21, min: 0.01, max: 0.5, step: 0.01 }
  });

  return (
    <>
 
      {!isDebugMode && <Leva hidden />}
      <Canvas>
        <CameraShake yawFrequency={1} maxYaw={0.05} pitchFrequency={1} maxPitch={0.05} rollFrequency={0.5} maxRoll={0.5} intensity={0.2} />
        <Particles position={[0, 0, 3]} {...props} />
      </Canvas>
    </>
  );
}