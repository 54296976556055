export default function Imprint() {


    return (
      <div className="imprint-overlay">
          <strong>BavMind UG</strong> (haftungsbeschränkt)<br/>
          <br/>
          Wachendorfer Weg 47, 90449 Nürnberg<br/>
          Telefonnummer: 0162 - 39 50 974<br/>
          E-Mail: hello@bavmind.com<br/>
          <br/>
          Registergericht: <i>Amtsgericht Nürnberg</i><br/>
          Registernummer: <i>HRB 42231</i><br/>
          <br/>
          <strong>Geschäftsführer:</strong>  <br/>
          Devran Ünal<br/>
          André Wirth<br/>
      </div>
    )
  }