import { Container, Center, TopLeft, BottomRight, Hamburger } from './styles'

export default function Overlay({ onMouseEnter, onMouseLeave }) {


  const handleClick = event => {
    if (document.body.classList.contains('imprint-active')) {
      document.body.classList.remove('imprint-active');
    } else {
      document.body.classList.add('imprint-active');
    }
  };

  return (
    <Container>
      <div className="center-shadow"></div>
      <TopLeft>
        <div className="icon">
          <svg width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.0797 22.4059L35.4229 25.0618L37.6039 27.2422C38.3359 27.9732 39.5269 27.9732 40.2598 27.2422C40.9911 26.5093 40.9911 25.318 40.2588 24.5854L38.0797 22.4059Z" fill="white"/>
            <path d="M3.98729 18.9006L6.64318 16.2438L6.20048 15.8017C3.43872 13.0418 3.44345 8.57639 6.20206 5.82125C8.97926 3.04216 13.4529 3.08911 16.1819 5.82125C18.8687 8.50802 27.0761 16.7155 29.2612 18.9006V18.9016L31.9181 16.2447V16.2438C31.9004 16.2261 18.8564 3.18269 18.8387 3.16504H18.8378C14.6219 -1.04928 7.76175 -1.0518 3.54522 3.16504C-0.670673 7.38252 -0.670673 14.243 3.54522 18.4585L3.98729 18.9006Z" fill="white"/>
            <path d="M38.5216 18.4588C42.7366 14.2429 42.7366 7.38247 38.5207 3.16499C34.3013 -1.05594 27.4462 -1.05405 23.2272 3.16499L22.7851 3.60707L25.4413 6.26327L25.8831 5.8212C28.6363 3.06826 33.1033 3.05976 35.8645 5.8212C38.6199 8.57666 38.6212 13.0468 35.8645 15.8017C33.6217 18.0448 27.2288 24.4383 26.3507 25.3164C23.5038 28.1598 18.4488 28.0511 15.7148 25.3158L12.8043 22.4053L10.1484 25.0612L13.0589 27.9717C17.3441 32.2607 24.7825 32.1967 29.0069 27.9726C30.438 26.5415 37.4481 19.532 38.5216 18.4588Z" fill="white"/>
            <path d="M16.6242 9.76834C6.11912 20.275 8.49174 17.8995 1.10731 25.2859C0.370313 26.0229 0.372834 27.2167 1.11645 27.9503C1.8484 28.6721 3.03566 28.669 3.76351 27.9411C4.25757 27.4468 18.6379 13.0667 19.2801 12.4245L16.6242 9.76834Z" fill="white"/>
          </svg>
        </div>
      </TopLeft>
      <Center>
        <div className="overlay">
          <img id="logo" src='BavMind.svg' />
          <p>is a digital product company focused on AI. <br/>
            We develop cutting-edge B2B solutions.  <br/>
            Most recently working on <a onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} href="https://getagentwise.com/" target="_blank">Agentwise</a>.</p>
        </div>
      </Center>
      <BottomRight>
        <div className="imprint" onClick={handleClick}>
          <span className="imprint-txt-imprint" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >Imprint</span>
          <span className='imprint-txt-close' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >Close</span>
        </div>
      </BottomRight>
    </Container>
  )
}